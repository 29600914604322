
.container {
  max-width: 1280px !important;
}

iframe {
  display: none;
}

.link {
  text-decoration: underline;
  color: #007bff !important;
}

.tabs-header a {
  color: #004982 !important;
}

.tabs-header .focused a {
  color: #fff !important;
}

